import { Button, Row } from "antd";
import styled from "styled-components";

export const Container = styled(Row)`   
   background-color:  #fff;
   margin:  10px;
   padding: 8px;
`;

export const BtDetalhe = styled(Button)`
   background-color: #42afd9;
   color: #fff;
`;

export const BtContato = styled(Button)`
   background-color: #737373;
   color: #fff;
`;

export const BtExcluir= styled(Button)`
   background-color: #fb2025;
   color: #fff;
`;

export const BtCliente= styled(Button)`
   background-color: #84bc5f;
   color: #fff;
`;
