import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Table,
    Tag,
  } from "antd";
  import Search from "antd/es/input/Search";
  import { useEffect, useState } from "react";
  import { mask } from "../../Helpers/UsaGeral";
  import { BtContato, BtDetalhe, Container } from "./style";
  import { SearchOutlined } from "@ant-design/icons";
  import { useClientes } from "../../Services/Clientes/useClientes";
  import { useUsuario } from "../../Contexts/auth";
  import adicionarBtn from "./../../../src/Assets/add-button.png";
  import { useContatos } from "../../Services/Contatos";
  import axios from "axios";
import { useBackup } from "../../Services/backups";
  
  
  const data = [
    {
      ID_CLIENTE: "526",
      cod: 526,
      FANTASIA: "John Brown",
      NOME_COMERCIAL: "New York No. 1 Lake Park",
      STATUS_CLIENTE: "S",
    },
    {
      ID_CLIENTE: "2",
      cod: 2,
      FANTASIA: "Jim Green",
      NOME_COMERCIAL: "London No. 1 Lake Park",
      STATUS_CLIENTE: "S",
    },
    {
      ID_CLIENTE: "3",
      cod: 3,
      FANTASIA: "Joe Black",
      NOME_COMERCIAL: "Sidney No. 1 Lake Park",
      STATUS_CLIENTE: "S",
    },
  ];
  
  const dataInicialCliente = [
    {
      nomeComercial: "",
      fantasia: "",
      cnpj: "",
      cpf: "",
      inscricaoEstadual: "",
      statusCliente: "S",
      tipoCliente: "C",
      tipoEmpresa: "F",
    },
  ];
  
  export function Backup() {
    const [open, setOpen] = useState(false);
  
    // const [cliente, setCliente] = useState(dataInicialCliente);
    const [dadosCliente, setClientes] = useState([]);
    const [dadosSelecionados, setDadosSelecionados] = useState();
    const { user } = useUsuario(); 
    const [statusCliente, setStatusCliente] = useState("S");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [load , setLoad] = useState(false); 
    const [loadItens , setLoadItens] = useState(false); 
    const [dadosDetalhes, setDadosDetalhes] = useState([]); 
    const [isAdd, setIsAdd] = useState(false);
    const [filtro , setFiltro] = useState('');
    const [parametros, setParametros] = useState('&ativo=S&controla_backup=S');
    

  
    const columns = [
      {
        title: "Contrato",
        dataIndex: "contrato",
        key: "contrato",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Fantasia",
        dataIndex: "fantasia",
        key: "fantasia",
      },
      {
        title: "Razão Social",
        dataIndex: "razaoSocial",
        key: "razaoSocial",
      },
      {
        title: "Status",
        dataIndex: "ativo",
        key: "ativo",
      },
      {
        title: "Ultimo Backup",
        dataIndex: "ultimoBackup",
        key: "ultimoBackup",
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <BtDetalhe value={record.key} onClick={()=>showModal(record)}>Detalhes</BtDetalhe>        
          </Space> 
        ),
      },
    ];   

    const columnsContatos = [
      {
        title: 'ID',
        dataIndex: 'idbackup',
        key: 'idbackup',
      },
      {
        title: 'Nome',
        dataIndex: 'backupNome',
        key: 'backupNome',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Data',
        dataIndex: 'data',
        key: 'data',
      },
      {
        title: 'TAMANHO',
        dataIndex: 'tamanho',
        key: 'tamanho',
      },
    ];
  
    async function CarregaDados() {
      setLoad(true)
      const api = await useBackup();
      let dados = {
        ativo : 'S',
        controlaBackup : 'S',
        descPesquisa : filtro
      }
      await api.Consulta(dados,user.TOKEN)
      .then((res)=>{      
          setClientes(JSON.parse(res.data));      
      })
      setLoad(false)
    }
    async function CarregaDadosBackup(dados) {
      setLoadItens(true)
      const api = await useBackup();

      await api.DetalhesConsulta(dados,user.TOKEN)
      .then((res)=>{  
          console.log(res.data);
          setDadosDetalhes(res.data);     
      })
      setLoadItens(false)
    }
    useEffect(() => {
      CarregaDados();
    },[]);
  
    const showModal = (dados) => {
      console.log(dados)   
      setDadosSelecionados(dados)
      CarregaDadosBackup(dados.contrato)       
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    const onClose = () => {
      setOpen(!open);
    };

    const SalvarContato = async(dados, cliente) => {
      // Salvar dados do contato
      console.log(dados,cliente?.ID_CLIENTE);
      const api = await useContatos();
      await api.Salvar(dados,cliente?.ID_CLIENTE,user.TOKEN)
      CarregaDadosBackup(cliente?.ID_CLIENTE)
      setIsAdd(false)
    }
    return (
      <Row style={{ flexDirection: "column" }}>
        <Container>
        <div
          style={{
            margin: "10px",
            textAlign: "right",
            width: "100%",
            height: 30,
          }}
        >
          
        </div>
          <Row style={{ width: "100%", alignItems: "flex-end", marginBottom: 15 }}>
            <Col xs={24}  sm={24} md={24} lg={12} >
              <label for="cliente">Pesquisar:</label>
              <Search id="cliente" placeholder="pesquisa" 
              value={filtro} onChange={(e)=>setFiltro(e.target.value)}/>
            </Col>
  
            <Col  xs={24}  sm={24} md={24} lg={4}>
              <label>Status:</label>
              <Select
                style={{ width: "100%" }}
                options={[
                  { value: "s", label: "Ativo" },
                  { value: "n", label: "Inativo" },
                ]}
                defaultValue={"s"}
              ></Select> 
            </Col>
            <Col  xs={24}  sm={24} md={24} lg={4}>   
              <Button type="primary" icon={<SearchOutlined />} onClick={() => CarregaDados() }> 
                Pesquisar
              </Button>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col span="24">
              <Table columns={columns} dataSource={dadosCliente}  size={'small'} loading={load}/>
            </Col>
          </Row>
        </Container>
        <Modal
          title={dadosSelecionados?.contrato +'-'+dadosSelecionados?.razaoSocial}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={'80%'}
        >
         <p>Backups : </p>
       
         
            <Table columns={columnsContatos} dataSource={dadosDetalhes} loading={loadItens}/>

                  
        </Modal>


        
      </Row>
    );
  }
  