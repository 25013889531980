import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { mask, mensagem } from "../../Helpers/UsaGeral";
import { BtContato, BtDetalhe, BtExcluir, Container } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import adicionarBtn from "./../../../src/Assets/add-button.png";
import TextArea from "antd/es/input/TextArea";
import { useRemoto } from "../../Services/remoto";

const data = [
  {
    ID_CLIENTE: "526",
    cod: 526,
    FANTASIA: "John Brown",
    NOME_COMERCIAL: "New York No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "2",
    cod: 2,
    FANTASIA: "Jim Green",
    NOME_COMERCIAL: "London No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "3",
    cod: 3,
    FANTASIA: "Joe Black",
    NOME_COMERCIAL: "Sidney No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
];

const dataInicialCliente = [
  {
    nomeComercial: "",
    fantasia: "",
    cnpj: "",
    cpf: "",
    inscricaoEstadual: "",
    statusCliente: "S",
    tipoCliente: "C",
    tipoEmpresa: "F",
  },
];

export function Remoto() {
  const [open, setOpen] = useState(false);
  const { user } = useUsuario();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCadastrar, setIsModalOpenCadastrar] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [dadosSelecionados, setDadosSelecionados] = useState();
  const [dataContatos, setDataContatos]= useState([]);
  const [dataAcesso, setDataAcesso]= useState([]);
  const [load, setLoad] = useState(false);
  const [loadItens, setLoadItens] = useState(false);
  

  const [filtro, setFiltro] = useState("");

  const columns = [    
    {
      title: "ID",
      dataIndex: "ID_ACESSO_REMOTO",
      key: "ID_ACESSO_REMOTO",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Contrato",
      dataIndex: "COD_INTERNO_CLIENTE",
      key: "COD_INTERNO_CLIENTE",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Descricao",
      dataIndex: "DESCRICAO_ACESSO",
      key: "DESCRICAO_ACESSO",
    },
    {
      title: "Status",
      dataIndex: "STATUS_ACESSO",
      key: "STATUS_ACESSO",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <BtDetalhe value={record.key} onClick={() => showModal(record)}>
            Detalhes
          </BtDetalhe>
          <BtExcluir value={record.key} onClick={() => ExcluirRemoto(record)}>
            Excluir
          </BtExcluir>
        </Space>
      ),
    },
  ];

  const columnsContatos = [
    {
      title: "TeamView",
      dataIndex: "TEAMVIEWER_ACESSO",
      key: "TEAMVIEWER_ACESSO",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Senha",
      dataIndex: "SENHA_TEAMVIEWER",
      key: "SENHA_TEAMVIEWER",
    },
    {
      title: "AnyDesk",
      dataIndex: "ANYDESK_ACESSO",
      key: "ANYDESK_ACESSO",
    },
    {
      title: "Senha",
      dataIndex: "SENHA_ANYDESK",
      key: "SENHA_ANYDESK",
    },
    {
      title: "IP TS",
      dataIndex: "IP_TERMINAL_SERVER",
      key: "IP_TERMINAL_SERVER",
    },
    {
      title: "Usuario Ts",
      dataIndex: "USUARIO_TERMINAL_SERVER",
      key: "USUARIO_TERMINAL_SERVER",
    },
    {
      title: "Senha Ts",
      dataIndex: "SENHA_USUARIO_TERMINAL",
      key: "SENHA_USUARIO_TERMINAL",
    },
  ];

  async function CarregaDados() {
    setLoad(true)
    const api = await useRemoto();
    await api.ConsultaRemoto(filtro.toUpperCase(), user.TOKEN).then((res) => {
      console.log(res);
      setDataAcesso(res.data)
    });
    setLoad(false)
  }

  useEffect(() => {
     CarregaDados();
  }, []);

  const ExcluirRemoto = async(dados) => {   
    const api = await useRemoto();
    await api.delete(dados.ID_ACESSO_REMOTO, user.TOKEN)
    handleCancel()
    mensagem('sucess', 'Salvo com sucesso')
    CarregaDados();
  };


  const showModal = (dados) => {
    setDadosSelecionados(dados);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleOkAcesso = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpenCadastrar(false);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const SalvarRemoto = async(dados) => {
    const api = await useRemoto();;
    await api.Salvar(dados, user.TOKEN)
    handleCancel()
    mensagem('sucess', 'Salvo com sucesso')
    CarregaDados();
  };

  return (
    <Row style={{ flexDirection: "column" }}>
      <Container>
        <div style={{ margin: "10px", textAlign: "right", width: "100%" }}>
        <Button
            type="primary"
            style={{
              backgroundColor: "#05b305",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: 60,
            }}
            onClick={() => setIsModalOpenCadastrar(true)}
          >
            <p>
              <b>Adicionar</b>
            </p>
            <img src={adicionarBtn} width={32} />
          </Button>
        </div>
        <Row style={{ width: "100%", alignItems: "flex-end" , margin: 15 }}>
          <Col xs={24} sm={24} md={24} lg={12}>
            <label for="cliente">Filtro:</label>
            <Search
              id="cliente"
              placeholder="Filtro"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={4}>
            <label>Status:</label>
            <Select
              style={{ width: "100%" }}
              options={[
                { value: "Ativo", label: "Ativo" },
                { value: "Inativo", label: "Inativo" },
              ]}
              defaultValue={"Ativo"}
            ></Select>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => CarregaDados()}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col span="24">
            <Table
              columns={columns}
              dataSource={dataAcesso}
              size={"small"}
              loading={load}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        title={dadosSelecionados?.DESCRICAO_ACESSO}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
      >
        <p>Acesso : </p>
        <Table columns={columnsContatos} dataSource={[dadosSelecionados]} 
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.OBS}
            </p>
          ),
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }} />
      
      </Modal>
      <Modal
          title={'Acesso Remoto'}
          open={isModalOpenCadastrar}
          on
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ hidden: false }}
          cancelButtonProps={{ hidden: true }}
          footer={[]}
        >
         <Form
          onFinish={(e) => SalvarRemoto(e)}
          variant={"filled"}
          style={{ maxWidth: 600 }}
        >
          <Form.Item label="Contrato"  name="contrato"  rules={[{ required: false, type:'number' }]} >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Descricao"  name="descricao"  rules={[{ required: true, message: "Preencha o campo Descricao", min:3 }]}>
            <Input />
          </Form.Item>
          <Form.Item label="TeamViewer"  name="teamviewer"  rules={[{ required: false}]}>
            <Input />
          </Form.Item>
          <Form.Item label="Senha TeamViewer"  name="senhaTeam"  rules={[{ required: false}]}>
            <Input />
          </Form.Item>
          <Form.Item label="AnyDesk" name="anydesk" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Senha AnyDesk" name="senhaAnydesk" rules={[{ required: false}]}>
            <Input />
          </Form.Item>
          <Form.Item label="IP TS" name="ipts" rules={[{ required: false}]}>
            <Input />
          </Form.Item>
          <Form.Item label="Usuario TS" name="usuariots" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Senha TS" name="senhats" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Obs" name="obs" rules={[{ required: false }]}>
            <TextArea />
          </Form.Item>
            <Button style={{backgroundColor:"red", color: 'white'}} onClick={()=>setIsModalOpenCadastrar(false)}>Cancelar</Button>  
            <Button style={{backgroundColor:"green", color: 'white'}} htmlType="submit" type="primary">Salvar</Button>  
          </Form>        
        </Modal>
    </Row>
  );
}
