import { DownOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Dropdown, Form, Input, Modal, Row, Select, Space, Table } from "antd";
import Search from "antd/es/transfer/search";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Container } from "./style";
import Dragger from "antd/es/upload/Dragger";
import TextArea from "antd/es/input/TextArea";
import { InboxOutlined } from '@ant-design/icons';

const dataCliente = [
  {
    key: "1",
    ID_CLIENTE: "526",
    FANTASIA: "EDSON AUTOPEÇAS",
    NOME_COMERCIAL: "EDSON AUTOPEÇAS",
    STATUS_CLIENTE: "S",
  },
  {
    key: "2",
    ID_CLIENTE: "540",
    FANTASIA: "SORPACK LTDA",
    NOME_COMERCIAL: "SORPACK LTDA",
    STATUS_CLIENTE: "S",
  },
  {
    key: "3",
    ID_CLIENTE: "374",
    FANTASIA: "CUIABA MADEIRAS",
    NOME_COMERCIAL: "CUIABA MADEIRAS",
    STATUS_CLIENTE: "S",
  },
];

const columnsClientes = [
  {
    title: "Contrato",
    dataIndex: "ID_CLIENTE",
    key: "ID_CLIENTE",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Fantasia",
    dataIndex: "FANTASIA",
    key: "FANTASIA",
  },
  {
    title: "Razão Social",
    dataIndex: "NOME_COMERCIAL",
    key: "NOME_COMERCIAL",
  },
  {
    title: "Status",
    dataIndex: "STATUS_CLIENTE",
    key: "STATUS_CLIENTE",
  },
];

const columnsContatos = [
  {
    title: "Tipo Telefone",
    dataIndex: "telefone",
    key: "telefone",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Numero",
    dataIndex: "numero",
    key: "numero",
  },
  {
    title: "Setor",
    dataIndex: "setor",
    key: "setor",
  },
];

const dataContatos = [
  {
    key: "1",
    telefone: "Fixo",
    numero: "3621-5895",
    setor: "ADRIANA",
  },
  {
    key: "2",
    telefone: "Whatssap",
    numero: "3621-5895",
    setor: "COMERCIAL",
  },
  {
    key: "3",
    telefone: "Celular",
    numero: "3621-5895",
    setor: "IVAN",
  },
];

export function ConsultaChamados() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const expandedRowRender = () => {
    const columns = [
      {
        title: "Sequecia",
        dataIndex: "ID_MOVIMENTO",
        key: "ID_MOVIMENTO",
      },
      {
        title: "Colaborador",
        dataIndex: "COD_COLABORADOR_SOLICITADO",
        key: "COD_COLABORADOR_SOLICITADO",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "state",
      },
      {
        title: "Data Movimentação",
        dataIndex: "dtMovimentacao",
        key: "dtMovimentacao",
      },
      {
        title: "",
        dataIndex: "",
        key: "operation",
        render: () => (
          <Space size="middle">
            <Button type="primary" size="small" onClick={() => showModal()}>
              Detalhes
            </Button>
            <Button type="link" size="small">
              Editar
            </Button>
          </Space>
        ),
      },
    ];
    const data = [
      {
        ID_MOVIMENTO: 1,
        COD_COLABORADOR_SOLICITADO: "NILVA",
        status: "ABERTURA CHAMADO",
        dtMovimentacao: "06-03-2023 12:35",
      },
      {
        ID_MOVIMENTO: 2,
        COD_COLABORADOR_SOLICITADO: "UDENILSON",
        status: "ENVIADO PARA ANALISTA ESPECIALIZADO",
        dtMovimentacao: "06-03-2023 12:38",
      },
      {
        ID_MOVIMENTO: 2,
        COD_COLABORADOR_SOLICITADO: "UDENILSON",
        status: "EM ANALISE PELO ESPECIALISTA",
        dtMovimentacao: "06-03-2023 14:35",
      },
      {
        ID_MOVIMENTO: 3,
        COD_COLABORADOR_SOLICITADO: "ATIMILSON",
        status: "ENVIADO PARA PROGRAMACAO",
        dtMovimentacao: "06-03-2023 14:38",
      },
      {
        ID_MOVIMENTO: 4,
        COD_COLABORADOR_SOLICITADO: "ATIMILSON",
        status: "EM ANALISE PELA PROGRAMACAO",
        dtMovimentacao: "06-03-2023 14:44",
      },
    ];

    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  const columns = [
    {
      title: "Chamado",
      dataIndex: "chamado",
      key: "chamado",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Projeto",
      dataIndex: "projeto",
      key: "projeto",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Tipo",
      dataIndex: "tipoChamado",
      key: "tipoChamado",
    },
    {
      title: "Date Abertura",
      dataIndex: "dataAbertura",
      key: "dataAbertura",
    },
    {
      title: "",
      key: "",
      render: () => (
        <Button type="primary" size="small" onClick={()=>setOpen(!open)}>
          Movimentar
        </Button>
      ),
    },
  ];
  const data = [
    {
      chamado: 1520,
      cliente: "526",
      projeto: "ERP MCN",
      status: "8-EM DESENVOLMENTO",
      tipoChamado: "2-FALHAS NORMAIS",
      dataAbertura: "06-03-2023 10:12:00",
    },
    {
      chamado: 1521,
      cliente: "540",
      projeto: "ERP MCN",
      status: "4-RECEBIDO PELO ANALISTA",
      tipoChamado: "2-FALHAS NORMAIS",
      dataAbertura: "06-03-2023 10:20:00",
    },
    {
      chamado: 1522,
      cliente: "633",
      projeto: "ERP MCN",
      status: "17-PENDENTE RESOLUCAO",
      tipoChamado: "2-FALHAS NORMAIS",
      dataAbertura: "06-03-2023 08:20:00",
    },
  ];

  return (
    <>
      <Container>
        <Row
          style={{ width: "100%", justifyContent: "center" }}
          gutter={[15, 15]}
        >
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="cliente">Cliente:</label>
            <Search id="cliente" placeholder="cliente" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="colaborador">Colaborador:</label>
            <Search id="colaborador" placeholder="Colaborador" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="nrchamado">Numero Chamado:</label>
            <Search id="nrchamado" placeholder="chamado" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={10}>
            <label for="Setor">Setor:</label>
            <Search id="Setor" placeholder="Setor" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <label for="Status">Status:</label>
            <Search id="Status" placeholder="Status" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <label for="Status">Dara:</label>
            <Search id="Status" placeholder="Data" />
          </Col>
          <Col xs={24} sm={24} md={24} lg={4}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              style={{ marginTop: "18px" }}
              onClick={() => console.log("")}
            >
              Pesquisar
            </Button>
          </Col>
        </Row>
      </Container>
      <Table
        columns={columns}
        expandable={{
          expandedRowRender,
          defaultExpandedRowKeys: ["0"],
        }}
        dataSource={[]}
      />

      <Modal
        title="Detalhes Chamado"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>
          <b>Chamado</b> : 1520{" "}
        </h2>
        <p>
          <b>Sequencia</b> : 1
        </p>
        <br />
        <p>
          <b>Status</b> : ABERTURA CHAMADO
        </p>
        <br />
        <p>
          <b>Colaborador</b> : NILVA
        </p>
        <br />
        <p>
          <b>Detahes</b> : Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book
        </p>
        <p>
          <b>Banco</b> : EDSONDADOS.FDB
        </p>

        <p>ANEXOS : </p>
      </Modal>

      <Modal
        title="Movimentar Chamado 1520"
        open={open}
        onOk={()=>setOpen(!open)}
        onCancel={()=>setOpen(!open)}
        okText="Salvar"
        cancelText="Cancelar"
      >
        <Form>
          <Form.Item label="Status " validateStatus={""} help="">
            <Select
              showSearch
              placeholder="Selecione"
              optionFilterProp="children"  
            />
          </Form.Item>

          <Form.Item label="Colaborador Responsavel" validateStatus={""} help="">
            <Select
              showSearch
              placeholder="Selecione"
              optionFilterProp="children"  
            />
          </Form.Item>

          <Form.Item label="Nome Banco de Dados " validateStatus={""} help="">
            <Input type="text" row />
          </Form.Item>

          <Form.Item label="Detalhes " validateStatus={""} help="">
            <TextArea showCount maxLength={1000} rows={10} />
          </Form.Item>

          <Form.Item label="Uploads " validateStatus={""} help="">
            <Dragger
              multiple={true}
              onRemove={(item) => {
                console.log(item);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click ou Arraste o arquivo para a area de upload
              </p>
              <p className="ant-upload-hint">
                Arraste um ou Varios Arquivos ao mesmo tempo.
              </p>
            </Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
