import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import Search from "antd/es/input/Search";
import React, { useEffect, useState } from "react";
import { mask, mensagem } from "../../../Helpers/UsaGeral.js";
import { BtContato, BtDetalhe, Container } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions/index.js";
import { useClientes } from "../../../Services/Clientes/useClientes.js";
import { useUsuario } from "../../../Contexts/auth.js";
import { useChamados } from "../../../Services/Chamados/useChamados.js";
import TextArea from "antd/es/input/TextArea.js";
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';


const { Dragger } = Upload;

export function AbrirChamados() {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [statusCliente, setStatusCliente] = useState("S");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listarModulos, setListaModulos] = useState([]);
  const [listarTelas, setListarTelas] = useState([]);
  const [listarTipos, setListarTipos] = useState([]);
  const [listarStatus, setListarStatus] = useState([]);
  const [dadosCliente, setClientes] = useState([]);

  const [chamado, setChamado] = useState({
    projeto : '',
    modulo : '',
    tela : '',
    cliente : '',
    tipo : '',
    status : '',
    bancoDedados : '',
    detalhes : '',
    uploads : uploadedFiles
  });

  const { user } = useUsuario();

  const [projetos, setProjetos] = useState("1");
  const [modulos, setModulos] = useState();

  const [tela, setTela] = useState();
  const [cliente, setCliente] = useState();
  const [tipo, setTipo] = useState();
  const [status, setStatus] = useState();
  const [banco, setBanco] = useState("");
  const [detalhes, setDetalhes] = useState("");

  async function CarregaDadosCliente() {
    const api = await useChamados();
    await api.ConsultaClientes(user.TOKEN).then((res) => {
      console.log(res);
      setClientes((V) => res.data);
    });
  }

  async function CarregaDadosModulos() {
    const api = await useChamados();
    await api.ConsultaModulo(user.TOKEN).then((res) => {
      if (res.status !== 200) {
        return;
      }
      setListaModulos((V) => res.data);
    });
  }

  async function CarregaDadosTelas() {
    const api = await useChamados();
    await api.ConsultaTelas(user.TOKEN).then((res) => {
      console.log(res);
      setListarTelas((V) => res.data);
    });
  }

  async function CarregaDadosTipos() {
    const api = await useChamados();
    await api.TiposChamados(user.TOKEN).then((res) => {
      setListarTipos(() => res.data);
    });
  }

  async function CarregaDadosStatus() {
    const api = await useChamados();
    await api.StatusChamados(user.TOKEN).then((res) => {
      setListarStatus(() => res.data);
    });
  }

  useEffect(() => {
    setLoading(true)
    CarregaDadosCliente();
    CarregaDadosModulos();
    CarregaDadosTelas();
    CarregaDadosTipos();
    CarregaDadosStatus();
    setLoading(false)
  }, []);

  const props = {
    action: 'http://suporte.mcnsistemas.net.br/api/uploads/',
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        setUploadedFiles(fileList);
        console.log(fileList);
      }
    }
  }

  // async function deleteArquivos(file: UploadFile){

  // }
  
  const onFinish = (values) => {
    // setFormValues(values);
    console.log("Valores do formulário: ", values);
    values.defaultPrevented = true;
    setChamado({
      projeto: projetos,
      modulo : modulos,
      tela : tela,
      cliente: cliente,
      tipo: tipo,
      status: status,
      bancoDedados: values.target[6].value,
      detalhes: values.target[7].value,
      uploads: uploadedFiles,
    })
    console.log(chamado)
  };

  return (
    <Row style={{ flexDirection: "column" }}>
      <Container>
        <div style={{ 
          width: "100%", 
          height: "25px", 
          textAlign: "center", 
          marginTop: "5px", 
          backgroundColor: "rgba(30,144,255,255)",
          borderRadius: "5px"}}>
          <h1 style={{ padding:'3px', color:'#FFFF'}}>Abrir Chamado</h1>
        </div>        
        <Col span={24} style={{ padding: "50px" }}>
          <Form onsubmit={onFinish}>
            <Form.Item label="Projeto" validateStatus={""} help="">
              <Select
                showSearch
                onChange={(e) => {
                  setProjetos(e);
                }}
                value={projetos}
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                
                }
                loading={loading}
              >
                <Option value="1">1 - MCN SISTEMA GERENCIAL</Option>
                <Option value="2">2 - MOBILE NFE</Option>
                <Option value="3">3 - PDV EXTERNO</Option>
                <Option value="4">4 - BI/CRM</Option>
                <Option value="5">5 - MOBILE VENDA EXTERNA</Option>
                <Option value="6">6 - MOBILE INVENTARIO</Option>
                <Option value="7">7 - MOBILE ENTREGA</Option>
                <Option value="8">8 - MOBILE CONSULTA PRECO</Option>
                <Option value="9">9 - MCN SUPORTE</Option>
              </Select>
            </Form.Item>
            {projetos === "1" ? (
              <div>
                <Form.Item label="Modulos" validateStatus={""} help="">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={(e) => setModulos(e)}
                    value={modulos}
                    loading={loading}
                  >
                    {listarModulos.map((el, i) => (
                      <Option value={el.CODIGO_MODULO}>
                        {el.DESCRICAO_MODULO}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Tela " name={'tela'} validateStatus={""} help="">
                  <Input  />                  
                </Form.Item>
              </div>
            ) : (
              ""
            )}          

            <Form.Item label="Cliente " validateStatus={""} help="">
              <Select
                showSearch
                placeholder="Selecione"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                onChange={(e) => {
                  setCliente(e);
                }}
                value={cliente}
                options={dadosCliente}
                loading={loading}
              ></Select>
            </Form.Item>
            <Form.Item label="Tipo " validateStatus={""} help="">
                  <Select
                    showSearch
                    placeholder="Selecione"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={listarTipos}
                    loading={loading}
                  />
                </Form.Item>
                <Form.Item label="Status " validateStatus={""} help="">
                  <Select
                    showSearch
                    placeholder="Selecione"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    onChange={(e) => {
                      setStatus(e);
                    }}
                    value={status}
                    options={listarStatus}
                    loading={loading}
                  />                                    
                </Form.Item>

            <Form.Item label="Nome Banco de Dados " validateStatus={""} help="">
              <Input type="text" row />
            </Form.Item>

            <Form.Item label="Detalhes" validateStatus={""} help="">
              <TextArea showCount maxLength={1000} rows={10} />
            </Form.Item>

          <Form.Item label="Uploads " validateStatus={""} help="">
          <Dragger {...props} multiple={true} headers={{Authorization :`Bearer ${user.TOKEN}`}}
            onRemove={(item) => {console.log(item)}}
          >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click ou Arraste o arquivo para a area de upload
                </p>
                <p className="ant-upload-hint">
                  Arraste um ou Varios Arquivos ao mesmo tempo. 
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item label="" validateStatus={""} help="" style={{textAlign: "center"}}>
              <Button type="primary" htmlType="submit" style={{width: "100%"}} onClick={()=>mensagem('error', 'erro ao abrir chamado (rota api)')}>
                Abrir Chamado
              </Button>
            </Form.Item>             
          </Form>
        </Col>
      </Container>
      
    </Row>
  );
}
