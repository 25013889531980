import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { mask } from "../../Helpers/UsaGeral";
import { BtContato, BtDetalhe, Container } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import { useContadores } from "../../Services/Contadores";


const data = [
  {
    ID_CLIENTE: "526",
    cod: 526,
    FANTASIA: "John Brown",
    NOME_COMERCIAL: "New York No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "2",
    cod: 2,
    FANTASIA: "Jim Green",
    NOME_COMERCIAL: "London No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "3",
    cod: 3,
    FANTASIA: "Joe Black",
    NOME_COMERCIAL: "Sidney No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
];

const dataInicialCliente = [
  {
    nomeComercial: "",
    fantasia: "",
    cnpj: "",
    cpf: "",
    inscricaoEstadual: "",
    statusCliente: "S",
    tipoCliente: "C",
    tipoEmpresa: "F",
  },
];

export function Clientes() {
  const [open, setOpen] = useState(false);

  // const [cliente, setCliente] = useState(dataInicialCliente);
  const [dadosCliente, setClientes] = useState();
  const { user } = useUsuario(); 
  const api = useClientes(); 
  const [nomeComercial, setNomeComercial] = useState("");
  const [fantasia, setFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cpf, setCpf] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [statusCliente, setStatusCliente] = useState("S");
  const [tipoCliente, setTipoCliente] = useState("");
  const [tipoEmpresa, setTipoEmpresa] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataContatos , setDataContatos] = useState([]); 
  const [loadContato , setLoadContato] = useState(false); 
  const [load , setLoad] = useState(false); 

  const [filtro , setFiltro] = useState('');

  const columns = [
    {
      title: "Contrato",
      dataIndex: "ID_CLIENTE",
      key: "ID_CLIENTE",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Fantasia",
      dataIndex: "FANTASIA",
      key: "FANTASIA",
    },
    {
      title: "Razão Social",
      dataIndex: "NOME_COMERCIAL",
      key: "NOME_COMERCIAL",
    },
    {
      title: "Status",
      dataIndex: "STATUS_CLIENTE",
      key: "STATUS_CLIENTE",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <BtDetalhe value={record.key} onClick={()=>showModal(record)}>Detalhes</BtDetalhe>        
        </Space> 
      ),
    },
  ];

  const columnsContatos = [
    {
      title: 'Tipo Telefone',
      dataIndex: 'TIPO_FONE',
      key: 'TIPO_FONE',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Numero',
      dataIndex: 'FONE',
      key: 'FONE',
    },
    {
      title: 'Setor',
      dataIndex: 'SETOR_FONE',
      key: 'SETOR_FONE',
    },
  ];

  async function CarregaDados() {
    setLoad(true)   
    await (await api).ConsultaClientes(filtro.toUpperCase(),user.TOKEN)
    .then((res)=>{      
        setClientes(V => res.data);      
    })
    setLoad(false)
  }

 
  useEffect(() => {
   CarregaDados();
  },[]);

  const onChange = () => {
    if (statusCliente === "S") {
      setStatusCliente("N");
    } else {
      setStatusCliente("S");
    }
  };

  const carregaContato = async(value) => {
    setLoadContato(true)
    await (await api).ConsultaContatosClientes(value.ID_CLIENTE,user.TOKEN)
    .then((res)=>{  
        console.log(res);    
        setDataContatos(res.data);      
    })
    setLoadContato(false)
  };

  const showModal = async(dados) => {
    carregaContato(dados)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClose = () => {
    setOpen(!open);
  };
  return (
    <Row style={{ flexDirection: "column" }}>
      <Container>
        <Row style={{ width: "100%", alignItems: "flex-end", margin: 15 }}>
          <Col xs={24}  sm={24} md={24} lg={12} >
            <label for="cliente">Cliente:</label>
            <Search id="cliente" placeholder="cliente" 
            value={filtro} onChange={(e)=>setFiltro(e.target.value)}/>
          </Col>

          <Col  xs={24}  sm={24} md={24} lg={4}>
            <label>Status:</label>
            <Select
              style={{ width: "100%" }}
              options={[
                { value: "Ativo", label: "Ativo" },
                { value: "Inativo", label: "Inativo" },
              ]}
              defaultValue={"Ativo"}
            ></Select> 
          </Col>
          <Col  xs={24}  sm={24} md={24} lg={4}>   
            <Button type="primary" icon={<SearchOutlined />} onClick={() => CarregaDados()} loading={load}>
              Pesquisar
            </Button>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <Col span="24">
            <Table columns={columns} dataSource={dadosCliente}  size={'small'} loading={load}/>
          </Col>
        </Row>
      </Container>
      <Modal
        title="Detalhes Cliente"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
      >
       <p>Contatos : </p>
          <Table columns={columnsContatos} dataSource={dataContatos} loading={loadContato} />
       <p>Email : Teste@gmail.com</p> 
      </Modal>
      
    </Row>
  );
}
