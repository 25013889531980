import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Form,
    Input,
    Modal,
    Radio,
    Row,
    Select,
    Space,
    Table,
    Tag,
  } from "antd";
  import Search from "antd/es/input/Search";
  import { useEffect, useState } from "react";
  import { mask } from "../../Helpers/UsaGeral";
  import { BtContato, BtDetalhe, Container } from "./style";
  import { SearchOutlined } from "@ant-design/icons";
  import { useClientes } from "../../Services/Clientes/useClientes";
  import { useUsuario } from "../../Contexts/auth";
  import adicionarBtn from "./../../../src/Assets/add-button.png";
import { useContatos } from "../../Services/Contatos";
  
  
  const data = [
    {
      ID_CLIENTE: "526",
      cod: 526,
      FANTASIA: "John Brown",
      NOME_COMERCIAL: "New York No. 1 Lake Park",
      STATUS_CLIENTE: "S",
    },
    {
      ID_CLIENTE: "2",
      cod: 2,
      FANTASIA: "Jim Green",
      NOME_COMERCIAL: "London No. 1 Lake Park",
      STATUS_CLIENTE: "S",
    },
    {
      ID_CLIENTE: "3",
      cod: 3,
      FANTASIA: "Joe Black",
      NOME_COMERCIAL: "Sidney No. 1 Lake Park",
      STATUS_CLIENTE: "S",
    },
  ];
  
  const dataInicialCliente = [
    {
      nomeComercial: "",
      fantasia: "",
      cnpj: "",
      cpf: "",
      inscricaoEstadual: "",
      statusCliente: "S",
      tipoCliente: "C",
      tipoEmpresa: "F",
    },
  ];
  
  export function Contato() {
    const [open, setOpen] = useState(false);
  
    // const [cliente, setCliente] = useState(dataInicialCliente);
    const [dadosCliente, setClientes] = useState();
    const [dadosSelecionados, setDadosSelecionados] = useState();
    const { user } = useUsuario(); 
    const [statusCliente, setStatusCliente] = useState("S");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [load , setLoad] = useState(false); 
    const [loadItens , setLoadItens] = useState(false); 
    const [dadosContato, setDadosContato] = useState([]); 
    const [isAdd, setIsAdd] = useState(false);
    const [filtro , setFiltro] = useState('');

    const columns = [
      {
        title: "Contrato",
        dataIndex: "ID_CLIENTE",
        key: "ID_CLIENTE",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Fantasia",
        dataIndex: "FANTASIA",
        key: "FANTASIA",
      },
      {
        title: "Razão Social",
        dataIndex: "NOME_COMERCIAL",
        key: "NOME_COMERCIAL",
      },
      {
        title: "Status",
        dataIndex: "STATUS_CLIENTE",
        key: "STATUS_CLIENTE",
      },
      {
        title: "",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <BtDetalhe value={record.key} onClick={()=>showModal(record)}>Detalhes</BtDetalhe>        
          </Space> 
        ),
      },
    ];
  
    const columnsContatos = [
      {
        title: 'Tipo Telefone',
        dataIndex: 'TIPO',
        key: 'TIPO',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'DDD',
        dataIndex: 'DDD',
        key: 'DDD',
      },
      {
        title: 'Telefone',
        dataIndex: 'FONE',
        key: 'FONE',
      },
    ];
  
    async function CarregaDados() {
      setLoad(true)
      const api = await useClientes();
      await api.ConsultaClientes(filtro.toUpperCase(),user.TOKEN)
      .then((res)=>{     
             
          setClientes(res.data);      
      })
      setLoad(false)
    }

    async function CarregaDadosContato(dados) {
      setLoadItens(true)
      const api = await useContatos();
      await api.Consulta(dados,user.TOKEN)
      .then((res)=>{ 
          console.log(res.data)       
          setDadosContato(res.data);      
      })
      setLoadItens(false)
    }
  
   
    useEffect(() => {
       CarregaDados();
    },[]);
  
    const onChange = () => {
      if (statusCliente === "S") {
        setStatusCliente("N");
      } else {
        setStatusCliente("S");
      }
    };
  
    const showModal = (dados) => {
      CarregaDadosContato(dados.ID_CLIENTE) 
      setDadosSelecionados(dados)  
      console.log(dados)   
      setIsModalOpen(true);
    };
  
    const handleOk = () => {
      setIsModalOpen(false);
    };
  
    const handleCancel = () => {
      setIsModalOpen(false);
    };
  
    const onClose = () => {
      setOpen(!open);
    };

    const SalvarContato = async(dados, cliente) => {
      // Salvar dados do contato
      console.log(dados,cliente?.ID_CLIENTE);
      const api = await useContatos();
      await api.Salvar(dados,cliente?.ID_CLIENTE,user.TOKEN)
      CarregaDadosContato(cliente?.ID_CLIENTE)
      setIsAdd(false)
    }
    return (
      <Row style={{ flexDirection: "column" }}>
        <Container>
        <div
          style={{
            margin: "10px",
            textAlign: "right",
            width: "100%",
            height: 30,
          }}
        >
          
        </div>
          <Row style={{ width: "100%", alignItems: "flex-end", marginBottom: 15 }}>
            <Col xs={24}  sm={24} md={24} lg={12} >
              <label for="cliente">Cliente:</label>
              <Search id="cliente" placeholder="cliente" 
              value={filtro} onChange={(e)=>setFiltro(e.target.value)}/>
            </Col>
  
            <Col  xs={24}  sm={24} md={24} lg={4}>
              <label>Status:</label>
              <Select
                style={{ width: "100%" }}
                options={[
                  { value: "Ativo", label: "Ativo" },
                  { value: "Inativo", label: "Inativo" },
                ]}
                defaultValue={"Ativo"}
              ></Select> 
            </Col>
            <Col  xs={24}  sm={24} md={24} lg={4}>   
              <Button type="primary" icon={<SearchOutlined />} onClick={() => CarregaDados() }> 
                Pesquisar
              </Button>
            </Col>
          </Row>
          <Row style={{ width: "100%" }}>
            <Col span="24">
              <Table columns={columns} dataSource={dadosCliente}  size={'small'} loading={load}/>
            </Col>
          </Row>
        </Container>
        <Modal
          title={dadosSelecionados?.ID_CLIENTE +'-'+dadosSelecionados?.NOME_COMERCIAL}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
         <p>Contatos : </p>
         <div style={{margin:'10px', textAlign: 'right', width: '100%'}}>
          <Button type="primary" onClick={()=>setIsAdd(!isAdd)}>Adicionar</Button>
         </div>
         {isAdd &&
         <div style={{ backgroundColor:'#f3f3f3', padding: 5, borderColor:'#000', borderWidth: 1, borderStyle:'dotted'}}>
         <Form
          onFinish={async(e) => await SalvarContato(e,dadosSelecionados)}
          variant={"filled"}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            label="Tipo"
            name="Tipo"
            rules={[{ required: true, message: "Preencha o campo Tipo" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="DDD"  name="DDD"  rules={[{ required: true, message: "Preencha o campo DDD", min:3, max:3 }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Telefone" name="telefone" rules={[{ required: true, message: "Preencha o campo Telefone", min:10, max:20 }]}>
            <Input />
          </Form.Item>
          <Button style={{backgroundColor:"red", color: 'white'}}>Cancelar</Button>  
          <Button style={{backgroundColor:"green", color: 'white'}} htmlType="submit" type="primary">Salvar</Button>  
          </Form>
          
          </div>
          }
            <Table columns={columnsContatos} dataSource={dadosContato}  loading={loadItens}/>

                  
        </Modal>


        
      </Row>
    );
  }
  