import { Routes, Route } from "react-router-dom";
import { LayoutMain } from "../Components/LayoutMain";
import { AbrirChamados } from "../Pages/Chamados/AbrirChamados";
import { ChamadosProgramacao } from "../Pages/Chamados/ChamadosProgramacao";
import { ConsultaChamados } from "../Pages/Chamados/ConsultaChamados";
import { LiberarProgramacao } from "../Pages/Chamados/LiberarProgramacao";
import { Producao } from "../Pages/Chamados/Producao";
import { Clientes } from "../Pages/Clientes";
import { Perfis } from "../Pages/Configuracao/Perfis";
import { Usuarios } from "../Pages/Configuracao/usuarios";
import { Contadores } from "../Pages/Contadores";
import { Contato } from "../Pages/Contato";
import { Dashboard } from "../Pages/Dashboard";
import { Home } from "../Pages/Home";
import { Login } from "../Pages/Login";
import { Remoto } from "../Pages/Remoto";
import { RotaValida } from "./rotaAuth";
import { Backup } from "../Pages/backup";

export function Rotas() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Login" element={<Login />} />
      <Route element={<LayoutMain />}> 
        <Route path="/Home" element={<RotaValida><Home /></RotaValida>} />
        <Route path="/Dashboard" element={<RotaValida><Dashboard /></RotaValida>} />
        <Route path="/Clientes" element={<RotaValida><Clientes /></RotaValida>}/>
        <Route path="/Contato" element={<RotaValida><Contato /></RotaValida>} />
        <Route path="/Remoto" element={<RotaValida><Remoto /></RotaValida>} />
        <Route path="/Contadores" element={<RotaValida><Contadores /></RotaValida>} />
        <Route path="/AbrirChamados" element={<RotaValida><AbrirChamados /></RotaValida>} />
        <Route path="/ChamadosProgramacao" element={<RotaValida><ChamadosProgramacao /></RotaValida>} />
        <Route path="/ConsultarChamados" element={<RotaValida><ConsultaChamados /></RotaValida>} />
        <Route path="/LiberarProgramacao" element={<RotaValida><LiberarProgramacao /></RotaValida>} />
        <Route path="/Producao" element={<RotaValida><Producao /></RotaValida>} />
        <Route path="/Usuario" element={<RotaValida><Home /></RotaValida>} />
        <Route path="/Perfis" element={<RotaValida><Home /></RotaValida>} />
        <Route path="/Backup" element={<RotaValida><Backup /></RotaValida>} />
      </Route>
    </Routes>
  );
}
