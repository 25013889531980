import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { mask, mensagem } from "../../Helpers/UsaGeral";
import { BtCliente, BtContato, BtDetalhe, BtExcluir, Container } from "./style";
import { SearchOutlined } from "@ant-design/icons";
import { useClientes } from "../../Services/Clientes/useClientes";
import { useUsuario } from "../../Contexts/auth";
import { useContadores } from "../../Services/Contadores";
import adicionarBtn from "./../../../src/Assets/add-button.png";

const data = [
  {
    ID_CLIENTE: "526",
    cod: 526,
    FANTASIA: "John Brown",
    NOME_COMERCIAL: "New York No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "2",
    cod: 2,
    FANTASIA: "Jim Green",
    NOME_COMERCIAL: "London No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
  {
    ID_CLIENTE: "3",
    cod: 3,
    FANTASIA: "Joe Black",
    NOME_COMERCIAL: "Sidney No. 1 Lake Park",
    STATUS_CLIENTE: "S",
  },
];

const dataInicialCliente = [
  {
    nomeComercial: "",
    fantasia: "",
    cnpj: "",
    cpf: "",
    inscricaoEstadual: "",
    statusCliente: "S",
    tipoCliente: "C",
    tipoEmpresa: "F",
  },
];

export function Contadores() {
  const [open, setOpen] = useState(false);

  // const [cliente, setCliente] = useState(dataInicialCliente);
  const [dadosContador, setContador] = useState([]);
  const { user } = useUsuario();
  const [isloading, setIsLoading] = useState(false);

  const [nomeComercial, setNomeComercial] = useState("");
  const [fantasia, setFantasia] = useState("");
  const [cgc, setCgc] = useState("");
  const [crc, setCrc] = useState("");
  const [statusCliente, setStatusCliente] = useState("S");
  const [detalhesContador, setDetalhesContador] = useState({});
  const [dataContatos, setDataContatos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCadastrar, setIsModalOpenCadastrar] = useState(false);

  const [filtro, setFiltro] = useState("");

  const columns = [
    {
      title: "id",
      dataIndex: "ID_CONTADOR",
      key: "ID_CONTADOR",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Contador",
      dataIndex: "NOME",
      key: "NOME",
    },
    {
      title: "CPF/CNPJ",
      dataIndex: "CGC",
      key: "CGC",
      render: (_, record) => mask(record.CGC),
      width: 180,
    },
    {
      title: "CRC",
      dataIndex: "CRC_CONTADOR",
      key: "CRC_CONTADOR",
      width: 180,
    },

    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <BtDetalhe value={record.key} onClick={() => showModal(record)}>
            Detalhes
          </BtDetalhe>
          <BtCliente value={record.key} onClick={() => mensagem('error', 'erro ao abrir formulario')}>
            Cliente
          </BtCliente>
          <BtExcluir
            value={record.key}
            onClick={() => DeleteContador(record.ID_CONTADOR)}
          >
            Excluir
          </BtExcluir>
        </Space>
      ),
    },
  ];

  const columnsContatos = [
    {
      title: "Numero 1",
      dataIndex: "FONE1_CONTADOR",
      key: "FONE1_CONTADOR",
    },
    {
      title: "Numero 2 ",
      dataIndex: "FONE2_CONTADOR",
      key: "FONE2_CONTADOR",
    },
    {
      title: "Numero 3",
      dataIndex: "FONE3_CONTADOR",
      key: "FONE3_CONTADOR",
    },
  ];

  const columnsClientes = [
    {
      title: "Contrato",
      dataIndex: "ID_CLIENTE",
      key: "ID_CLIENTE",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Fantasia",
      dataIndex: "FANTASIA",
      key: "FANTASIA",
    },
    {
      title: "Razão Social",
      dataIndex: "NOME_COMERCIAL",
      key: "NOME_COMERCIAL",
    },
    {
      title: "Status",
      dataIndex: "STATUS_CLIENTE",
      key: "STATUS_CLIENTE",
    },
  ];

  async function CarregaDados() {
    setIsLoading(true);
    const api = await useContadores();
    await api
      .ConsultaContadores(filtro.toUpperCase(), user.TOKEN)
      .then((res) => {
        setContador(res.data);
      });
    setIsLoading(false);
  }

  useEffect(() => {
    CarregaDados();
  }, []);

  const dataCliente = [];

  const showModal = (detalhes) => {
    console.log(detalhes);
    setDetalhesContador(detalhes);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onClose = () => {
    setOpen(!open);
  };

  const adicionaCgc = (value) => {
    setCgc(mask(value));
  };

  const SalvarContador = async (dados) => {
    console.log(dados);
    const api = await useContadores();
    await api.Salvar({ ...dados, cgc }, user.TOKEN).then((res) => {
      mensagem("sucess", "Salvo com sucesso !!!");
      CarregaDados();
      setCgc('')
      setIsModalOpenCadastrar(false);
    });
  };

  const DeleteContador = async (dados) => {
    const api = await useContadores();
    await api.delete(dados, user.TOKEN).then((res) => {
      mensagem("sucess", "Excluído com sucesso !!!");
      CarregaDados();
    });
  };
  return (
    <Row style={{ flexDirection: "column" }}>
      <Container>
        <Row
          style={{
            width: "100%",
            alignItems: "flex-end",
            marginBottom: 15,
            gap: 5,
          }}
        >
          <Col xs={24} sm={24} md={24} lg={8}>
            <label for="cliente">Contador(a):</label>
            <Search
              id="cliente"
              placeholder="Contador"
              value={filtro}
              onChange={(e) => setFiltro(e.target.value)}
            />
          </Col>

          <Col xs={24} sm={24} md={24} lg={4}>
            <label>Status:</label>
            <Select
              style={{ width: "100%" }}
              options={[
                { value: "Ativo", label: "Ativo" },
                { value: "Inativo", label: "Inativo" },
              ]}
              defaultValue={"Ativo"}
            ></Select>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4}>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={() => CarregaDados()}
              loading={isloading}
            >
              <b>Pesquisar</b>
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#05b305",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              height: 35,
            }}
            onClick={() => setIsModalOpenCadastrar(true)}
          >
            <p>
              <b>Adicionar</b>
            </p>
          </Button>
          </Col>
         
        </Row>
        <Row style={{ width: "100%" }}>
          <Col span="24">
            <Table
              columns={columns}
              dataSource={dadosContador}
              size={"small"}
              loading={isloading}
            />
          </Col>
        </Row>
      </Container>
      <Modal
        title={detalhesContador.NOME}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Contatos : </p>
        <Table columns={columnsContatos} dataSource={[detalhesContador]} />

        <p>Email 1 : {detalhesContador.EMAIL1_CONTADOR}</p>
        <p>Email 2 : {detalhesContador.EMAIL2_CONTADOR}</p>
        <p>Email 3 : {detalhesContador.EMAIL3_CONTADOR}</p>

        <p>Clientes Vinculados:</p>
        <Table columns={columnsClientes} dataSource={dataCliente} />
      </Modal>
      <Modal
        title="Cadastrar Contadores"
        open={isModalOpenCadastrar}
        onCancel={() => setIsModalOpenCadastrar(false)}
        okButtonProps={{ hidden: false }}
        cancelButtonProps={{ hidden: true }}
        footer={[]}
        style={{ minWidth: 1200 }}
      >
        <Form
          onFinish={(e) => SalvarContador(e)}
          variant={"filled"}
          style={{ minWidth: 1100,flex: 1,flexDirection:'column'  }}
        >
          <div style={{ flex: 1,flexDirection : 'column'}}>
            <div style={{ maxWidth : '50%'}}>
          <Form.Item
            label="Nome"
            name="Nome"
            rules={[{ required: true, message: "Preencha o campo Nome" }]}
          >

            <Input />
          </Form.Item>
          <Form.Item label="cpf/cnpj" required>
            <Input
              onChange={(Value) => adicionaCgc(Value.target.value)}
              value={cgc}
              required
              name="cgc"
              min={18}
              max={18}
              style={{ fontWeight: "bold" }}
            />
          </Form.Item>

          <Form.Item label="CRC" name="CRC">
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone - 1"
            name="tel1"
            rules={[{ message: "Preencha o campo Telefone" }]}
          >
            <Input />
          </Form.Item>
          </div>
          <div style={{ maxWidth : '50%'}}>
          <Form.Item
            label="Telefone - 2"
            name="tel2"
            rules={[{ message: "Preencha o campo Telefone" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telefone - 3"
            name="tel3"
            rules={[{ message: "Preencha o campo Telefone" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email - 1"
            name="email1"
            rules={[{ message: "Preencha o campo Email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email - 2"
            name="email2"
            rules={[{ message: "Preencha o campo Email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email - 3"
            name="email3"
            rules={[{ message: "Preencha o campo Email" }]}
          >
            <Input />
          </Form.Item>
          </div>
          </div>
          <Form.Item
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Button
              type="default"
              style={{ margin: 5 }}
              onClick={() => setIsModalOpenCadastrar(false)}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              style={{ margin: 5, backgroundColor: "#05b305" }}
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
}
